
var d = new Date();
d.setDate(d.getDate() - 1);
$('.mainContent .btn-default[style*="background: red"]')
    .append('<br>Revisado: ' + d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear());


function getOptsAnchor() {
    return [];
}

